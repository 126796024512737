<!--
   Created by Terence on 2024/2/23 - 11:23
-->
<template>
  <div class="walletpg">
    <div class="topbg" />
    <div class="info">
      <div class="info-title">保证金账户</div>
      <div class="info-money">¥{{ depositData.totalAmount }}</div>
      <div class="info-values">
        <div class="info-values-item">
          可用 <span class="value">¥{{ depositData.availableAmount }}</span>
        </div>
        <div class="info-values-item info-values-item-right">
          <span>
            被冻结
            <span class="value">¥{{ depositData.shouldFrozenAmount }}</span>
          </span>
          <span
            class="should-freeze"
            v-if="depositData.shouldFrozenAmount - depositData.frozenAmount > 0"
            >(平台垫付<span class="should-freeze-value"
              >¥{{
                depositData.shouldFrozenAmount - depositData.frozenAmount
              }}</span
            >)</span
          >
        </div>
      </div>
      <div class="info-btns">
        <van-button
          type="primary"
          plain
          size="medium"
          @click="handleToApp"
          :style="{ width: '40.8rem' }"
        >
          去App提现
        </van-button>

        <van-button
          type="primary"
          size="medium"
          @click="handleRecharge"
          :style="{ width: '40.8rem', marginLeft: '2.1333333333333333rem' }"
        >
          充值
        </van-button>
      </div>
      <div class="info-tips">
        每日可提现1次，支付宝最高¥3000，微信最高¥100，3个工作日内到账
      </div>
    </div>

    <deposit-recharge-popup
      v-model="isShowRechargePopup"
      :userDeposit="userDeposit"
      :depositRatio="depositRatio"
      :rechargeType="'defaultRecharge'"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { showFailToast } from "vant";
import { DuButton } from "dangoui";
import { Button as VanButton, Dialog as VanDialog } from "vant";
import WalletApi from "@/apis/wallet";
import { invokeAppByYYB } from "@/utils/mob";
import { REQUEST_OK } from "@/constant/network";
import DepositRechargePopup from "@/components/DepositRechargePopup/index.vue";
import DepositRechargeRulesDialog from "@/components/DepositRechargeRulesDialog/index.vue";
import { useDeposit } from "@/hooks/useDeposit";
import MainButton from "@/components/MainButton/index.vue";

const {
  ratio: depositRatio,
  userDeposit,
  getAvailablePrice,
  getDepositProportion,
} = useDeposit();

const test = ref("");
const depositData = ref("");
const isShowRechargePopup = ref(false);
const isShowTips = ref(true);

const handleToApp = () => {
  invokeAppByYYB({
    targetPath: "pay/wallet/fund-flow",
    params: { channelId: "GLOBAL_DEPOSIT" },
  });
};

const handleRecharge = () => {
  isShowRechargePopup.value = true;
};

const initDepositInfo = async () => {
  const res = await WalletApi.getDepositWallet();
  if (res?.code !== REQUEST_OK) {
    return showFailToast(res.message || "查询钱包失败");
  }
  const depositInfo = res?.data?.accounts.filter(
    (item) =>
      item.accountType === "Wallet" && item.amountType === "GLOBAL_DEPOSIT"
  )?.[0];
  console.log("depositInfo", depositInfo);

  depositData.value = depositInfo || {};
};

onMounted(() => {
  initDepositInfo();

  getAvailablePrice();
  getDepositProportion();
  setTimeout(() => {
    document.title = "我的钱包";
  }, 0);
});
</script>

<style lang="scss">
.walletpg {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  .topbg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 420rpx;
    width: 100%;
    background: url("https://cdn.qiandaoapp.com/interior/images/5d2983028b60ae6a3671248ee206a0f5.png");
    background-position: 0% -88rpx;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .info {
    position: relative;
    z-index: 2;
    margin: 32rpx auto 0 auto;
    display: flex;
    width: 690rpx;
    padding: 64rpx 30rpx;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16rpx;
    /* 💙 y:4 ; Blur=20（NFT 卡片投影） */
    box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    &-title {
      color: #000;
      text-align: center;
      font-size: 40rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 52rpx; /* 130% */
    }
    &-money {
      margin-top: 8rpx;
      color: #000;
      /* Number/N1 */
      font-family: "Roboto";
      font-size: 64rpx;
      font-style: normal;
      font-weight: 700;
      line-height: 80rpx; /* 125% */
    }
    &-values {
      width: 630rpx;
      margin-top: 42rpx;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-item {
        flex: 1;
        text-align: center;

        color: rgba(0, 0, 0, 0.64);
        font-size: 32rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 48rpx; /* 150% */

        .value {
          color: #000;
          font-family: "Roboto";
          font-size: 32rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 48rpx; /* 150% */
        }

        .should-freeze {
          color: rgba(0, 0, 0, 0.64);
          font-family: "Roboto";
          font-size: 20rpx;
          font-style: normal;
          line-height: 22rpx;
          &-value {
            color: #000;
            font-weight: 700;
          }
        }
      }
      &-item-right {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &-btns {
      display: flex;
      margin-top: 42rpx;
    }
    &-tips {
      margin-top: 40rpx;
      color: rgba(0, 0, 0, 0.4);
      font-size: 20rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 22rpx; /* 110% */
      text-transform: capitalize;
    }
  }
}

:root:root {
  .van-button--plain {
    color: #2b1ac0;
  }
  --van-button-border-width: 2rpx;
  --van-button-primary-border-color: #2b1ac0;
  --van-button-default-font-size: 28rpx;
  --van-button-default-font-weight: 500;
}
</style>
